<template>
    <!---Header--->
		<div class="ms_header">
			<div class="ms_top_left">
				<form class='' @submit.prevent="SearchData()">
				<div class="ms_top_search desktop" style="margin-right: 30px;">
					<input v-model="search" type="text" class="form-control" placeholder="بحث..">
					<span class="search_icon">
						<img src="../../assets/search.svg" alt="">
					</span>
				</div>
				</form>
				<!-- <div class="ms_top_search" style="margin-right: 30px;">
					<input type="text" class="form-control" placeholder="Search Music Here..">
					<span class="search_icon">
						<img src="../../assets/search.svg" alt="">
					</span>
				</div> -->
				<!-- <div class="ms_top_trend">
					<span><a href="#"  class="ms_color">Trending Songs :</a></span> <span class="top_marquee"><a href="#">Dream your moments, Until I Met You, Gimme Some Courage, Dark Alley (+8 More)</a></span>
				</div> -->
			</div>
			<div class="ms_top_right">
				<!-- <div class="ms_top_lang">
					<span data-bs-toggle="modal" data-bs-target="#lang_modal">languages <img src="assets/images/svg/lang.svg" alt=""></span>
				</div> -->
				<template v-if="showHeader">
					<a v-on:click="Logout" class="ms_btn Width768 pointer"><span>تسجيل الخروج</span></a>
					<router-link to="/Unsubscribes" class="ms_btn Width768"><span>الغاء الاشتراك</span></router-link>
				</template>
				<template v-else>
					<div class="ms_top_btn" style="display: flex;">
					<a @click="Subscrib()" class="ms_btn Width768 pointer"><span>اشتراك</span></a>
					<router-link to="/Login" class="ms_btn Width768"><span>دخول</span></router-link>
					<!-- <router-link to="/" ><img src="../../assets/Zain_logo.png" class="reg_btn logoSvg" alt=""></router-link> -->
                 </div>
				</template>
				 <div class="ms_top_btn" style="text-align: left;line-height: 30px;max-height: 40px;">
					<router-link to="/" ><img src="../../assets/images/logo1.png" class="reg_btn logoSvg" alt=""></router-link>
                 </div>
			</div>
		</div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { watchEffect } from '@vue/runtime-core';
import { HTTPSPAY } from '@/Api/http-spay';
import { useCookie } from 'vue-cookie-next';
import { useToast } from "vue-toastification";
export default {
   name: 'AppHeader',
   setup() {
        const router = useRouter();
        const route = useRoute();
		const cookie = useCookie()
		const toast = useToast();
        const search = ref([]);
		const showHeader = ref(false);

		watchEffect(() => {
            if (cookie.isCookieAvailable("status") && cookie.isCookieAvailable("msisdn")) {
                HTTPSPAY.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+ `&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status === 1 ) {
                        return;
                    } else {
                        cookie.removeCookie("MINTUES");
                        cookie.removeCookie("msisdn");
                        cookie.removeCookie("status");
                    }
                });
                showHeader.value = true;
            } else {
               showHeader.value = false;
            }
        });

        const SearchData = () => {
            router.push({ name: "Searchs", params: { search_id: search.value } });
        }
        const Logout = () => {
            toast.success('تم تسجيل الخروج', { timeout: 2500, });
			cookie.removeCookie("remming_minutes");
            cookie.removeCookie("msisdn");
            cookie.removeCookie("status");
			setTimeout(() => router.push({ path: "/" }), 2500);
			setTimeout(() => location.reload(), 2500);
			// location.reload();
        }
		const Subscrib = () => {
            window.open("http://sudanipay.com/subscription/nagham/Nagham", "_self");
        }
        return { search, SearchData, Subscrib ,showHeader, Logout};
    },
   
}
</script>

<style>
.logoSvg {
	width: 50%;
    margin-right: 3%;
    max-width: 572px;
    margin-top: -15px;
}
@media(max-width:992px) {
    .logoSvg {
		width: 55%;
        margin-bottom: 1%;
        margin-right: 5%;
		margin-top: -15px;
    }
}
@media(max-width:991px) {
.logoSvg {
		width: 45%;
        margin-bottom: 1%;
        margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:815px) {
.logoSvg {
		width: 45%;
        margin-bottom: 1%;
        margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:768px) {
.logoSvg {
		width: 45%;
		margin-bottom: 2%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:667px) {
.logoSvg {
		width: 45%;
		margin-bottom: 4%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:600px) {
.logoSvg {
		width: 45%;
		margin-bottom: 5%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:480px) {
.logoSvg {
		width: 45%;
		margin-bottom: 5%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:414px) {
.logoSvg {
		width: 55%;
		margin-bottom: 5%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:360px) {
.logoSvg {
		width: 50%;
		margin-bottom: 5%;
		margin-right: 5%;
		margin-top: -7px;
}
}
@media(min-width:1024px) {
.logoSvg {
		margin-top:-3px;
}
}
</style>